<template lang="pug">
  .member_item(:id="'id_' + account.id")
    router-link(:to="(this.$route.name === 'admin_list') ? {name: 'admin_edit', params: {id: account.id}} : {name: 'member', params: {id: account.id}}")
      .img_wrap
        ProfileImg(
          :id="account.id"
          :email="account.email"
          :profileImgName="account.profileImgName"
          :profileImgUrl="account.profileImgUrl"
          :isAccount="false"
        )
        span.position(v-if="!isGeneral(accountInfo.position)" :class="'pos_color_' + account.positionId") {{ accountInfo.position }}
      h3.name {{ accountInfo.name }}
</template>

<script>
import account from '@/mixins/account'
import ProfileImg from '@/components/ProfileImg'
export default {
  name: 'MemberItem',
  data() {
    return {
      accountInfo: {},
    }
  },
  props: {
    account: Object,
  },
  components: {
    ProfileImg,
  },
  mixins: [account],
  created() {
    this.accountInfo = this.formatAccount(this.account)
  },
}
</script>

<style lang="scss" scoped>
.member_item {
  a,
  a:visited {
    color: #88cabe;
    text-decoration: none;
    * {
      color: #333;
    }
    .img_wrap {
      position: relative;
      margin: 0 auto 15px;
      .position {
        position: absolute;
        display: inline-block;
        white-space: nowrap;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        padding: 7px 10px;
        font-size: 14px;
        border-radius: 40px;
        background: #ffcdac;
        color: #fff;
        text-shadow: 1px 1px 1px #afafaf3b;
        font-weight: bold;
        font-size: 12px;
        z-index: 5;
        &.pos_color_1,
        &.pos_color_2,
        &.pos_color_3,
        &.pos_color_4,
        &.pos_color_5,
        &.pos_color_15,
        &.pos_color_20 {
          background: #ffa3a3;
        }
        &.pos_color_8 {
          background: #88cabe;
        }
        &.pos_color_9,
        &.pos_color_14 {
          background: #87a2cb;
        }
        &.pos_color_10,
        &.pos_color_27 {
          background: #e0c89e;
        }
      }
    }
    .name {
      font-size: 14px;
    }
  }
}
</style>
